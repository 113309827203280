<template>

    <span>
    <v-layout mb-4>
      <v-flex xs10 ml-3>
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Departamentos - Inclusão</h2>
      </v-flex>
      <v-flex xs2 text-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'departments'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
        <form-department :btnLabel="btnLabel"></form-department>
    </span>
        
</template>

<script>
import FormDepartmentComponent from "./partial/FormDepartmentComponent";

export default {
  name: "AddDepartmentComponent",
  data () {
    return {
        btnLabel: 'Incluir'
    }
  },
  methods: {

  },
  components: {
    formDepartment: FormDepartmentComponent,
  }
};
</script>

<style scoped>

</style>
